
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import Map from '@/components/default-map/index.vue'
import { Desk } from '@/types/Desk'
import { useI18n } from 'vue-i18n'
import { useCountdown } from '@/composables/useCountDown'
import { RoadMapData } from '@/components/default-map/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/default-map/utils'

export default defineComponent({
    props: {
        mdata: {
            type: Object as PropType<Desk>,
            required: true
        }
    },
    components: {
        'default-map': Map
    },
    setup(props) {
        const { t } = useI18n()

        const mapData = ref<RoadMapData>(parseRoadMap(''))

        const { startCountDown, stopCountDown, currentCount } = useCountdown()

        const OnMapDataChange = (data: RoadMapData) => {
            mapData.value = data
            // console.log(mapData.value)
        }

        const tableName = computed(() => {
            if (props.mdata.gameType === 1)
                return `百家乐${props.mdata.tableNo}台`
            else return `龙虎${props.mdata.tableNo}台`
        })

        watch(
            () => props.mdata,
            (_value) => {
                if (_value.currentTime !== -1) {
                    startCountDown(_value.currentTime)
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )

        return {
            OnMapDataChange,
            tableName,
            mapData,
            currentCount,
            t
        }
    }
})
