
import { useStore } from '@/store'
import { VTextMarquee } from 'vue-text-marquee'
import {
    Emitter,
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import FuntanListItem from '@/components/loby/fantan-list-item.vue'
import ListItem from '@/components/loby/list-item.vue'

import { AppActions, AppMutation } from '@/store/types'
import { OtherApiParams, ACT, EventBuss } from '@/types/global'
import { AxiosSource, fetch } from '@/api'
import { useI18n } from 'vue-i18n'
import { ROUTES } from '@/router'
import { useRouter } from 'vue-router'
import { Desk } from '@/types/Desk'
import { getValueOfKeyValue } from '@/utils/api'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import { SVGRmb } from '@/assets/svg'
import axios from 'axios'

export default defineComponent({
    components: {
        'marquee-text': VTextMarquee,
        'fantan-list-item': FuntanListItem,
        'list-item': ListItem,
        'svg-coin': SVGRmb
    },
    setup() {
        const selectedTable = ref(-1)
        const router = useRouter()
        const { t } = useI18n()
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const noticeKey = ref(new Date().getTime())
        const PULL_INTERVAL = 3000
        const desks = ref<Array<Desk>>([])
        let failureCounter = 0
        let serviceTimeOut: number | boolean = false
        const listKey = ref(new Date().getTime())

        onBeforeMount(() => {
            emitter.on(EventBuss.WINDOW_RESIZED, handleResizeWindow)
        })

        onMounted(() => {
            loadData()
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.WINDOW_RESIZED, handleResizeWindow)
            stopPullingData()
        })

        const handleResizeWindow = () => {
            listKey.value = new Date().getTime()
        }

        const loadData = (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params = {
                ...OtherApiParams,
                act: ACT.MULTIPLE_DESK_INFO,
                sessionID: token.value,
                username: user.value,
                gameType: 3
            }

            store
                .dispatch(AppActions.FETCH_DATA, _params)
                .then((response) => {
                    failureCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)

                    if (response.includes('err=')) {
                        stopPullingData()
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                    } else {
                        desks.value = Desk.ParseList(response)

                        if (response.includes('$UserPoint=')) {
                            const _userPointValuePair =
                                response.split('$')[desks.value.length]

                            const _coin = parseInt(
                                getValueOfKeyValue(_userPointValuePair) ?? '0'
                            )
                            store.commit(AppMutation.SET_COIN, _coin)
                        }

                        serviceTimeOut = setTimeout(() => {
                            loadData(true)
                        }, PULL_INTERVAL)
                    }
                })
                .catch(() => {
                    failureCounter += 1

                    if (!_forceReload) {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))
                    }

                    if (failureCounter > 3) {
                        stopPullingData
                        emitter.emit(EventBuss.TOAST_MESSAGE, t('networkerror'))

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                        return
                    }

                    serviceTimeOut = setTimeout(() => {
                        loadData(true)
                    }, PULL_INTERVAL)
                })
        }

        const stopPullingData = () => {
            if (AxiosSource) {
                // cancell all axios pending request
                AxiosSource.Source.cancel()
                AxiosSource.Source = axios.CancelToken.source()
            }
            if (typeof serviceTimeOut === 'number') {
                clearTimeout(serviceTimeOut)
                serviceTimeOut = false
            }
        }

        const handleSelectTable = (tableNo: number) => {
            if (tableNo === selectedTable.value) selectedTable.value = -1
            else selectedTable.value = tableNo
        }

        const showDialog = (e: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: e
            }
            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const token = computed(() => store.getters['token'])
        const coin = computed(() => store.getters['coin'])
        const user = computed(() => store.getters['user'])
        const notice = computed(() => store.getters['lobyNotice'])
        const avatar = computed(() => store.getters['avatar'])
        return {
            notice,
            coin,
            user,
            noticeKey,
            listKey,
            desks,
            avatar,
            selectedTable,
            showDialog,
            handleSelectTable
        }
    }
})
