
import {
    Emitter,
    PropType,
    TableLimit,
    computed,
    defineComponent,
    inject,
    onBeforeUnmount,
    ref,
    watch
} from 'vue'
import Map from '@/components/fantan-map/index.vue'
import { Desk } from '@/types/Desk'
import { useCountdown } from '@/composables/useCountDown'
import { RoadMapData } from '@/components/fantan-map/datamodel/RoadMapData'
import { parseRoadMap } from '@/components/fantan-map/utils'
import { useI18n } from 'vue-i18n'
import { store } from '@/store'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, OtherApiParams } from '@/types/global'
import { DeskMoreInfoData } from '@/types/DeskMoreInfoData'

export default defineComponent({
    emits: ['select-table'],
    props: {
        mdata: {
            type: Object as PropType<Desk>,
            required: true
        },
        selected: {
            type: Number as PropType<number>
        }
    },
    components: {
        'road-map': Map
    },
    setup(props) {
        const emitter = inject('emitter') as Emitter
        const display = ref(0)
        const router = useRouter()
        const { t } = useI18n()

        const mapData = ref<RoadMapData>(parseRoadMap(''))

        const { startCountDown, stopCountDown, currentCount } = useCountdown()

        onBeforeUnmount(() => {
            stopCountDown()
        })

        const OnMapDataChange = (data: RoadMapData) => {
            mapData.value = data
            // console.log(mapData.value)
        }

        const enterTable = (xian: TableLimit) => {
            const _param = {
                ...OtherApiParams,
                xianId: xian.Id,
                desk: props.mdata.tableNo,
                act: ACT.GET_NOTICE_AND_LIMIT
            }

            store
                .dispatch(AppActions.FETCH_DATA, _param)
                .then((_data: string) => {
                    if (_data.includes('err=')) {
                        store.commit(AppMutation.HIDE_LOADING)
                        emitter.emit(EventBuss.SESSION_EXPIRED)
                    } else {
                        store.commit(AppMutation.SET_LIMIT, xian)

                        const { limit, chipsVallue, notice } =
                            DeskMoreInfoData.Parse(_data)
                        const _limit = limit

                        if (_limit) {
                            _limit.Id = _param.xianId
                            store.commit(AppMutation.SET_LIMIT, _limit)
                        }

                        if (chipsVallue)
                            store.commit(AppMutation.SET_CHIPS, chipsVallue)
                        if (notice)
                            store.commit(AppMutation.SET_DESK_NOTICE, notice)

                        router.push({
                            name: ROUTES.FANTAN_GAME,
                            query: { deskNo: props.mdata.tableNo }
                        })
                    }
                })
                .catch((_error) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.TOAST_MESSAGE, _error)
                })
        }

        const timerColor = computed(() => {
            if (currentCount.value > 20) return 'normal'
            else if (currentCount.value <= 20 && currentCount.value > 10)
                return 'warning'
            else return 'lastcall'
        })
        const limits = computed((): TableLimit[] => store.getters['limits'])
        watch(
            () => props.mdata,
            (_value) => {
                if (_value.currentTime !== -1) {
                    startCountDown(_value.currentTime)
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )

        return {
            mapData,
            display,
            currentCount,
            timerColor,
            limits,
            enterTable,
            t,
            OnMapDataChange
        }
    }
})
